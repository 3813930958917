import app from "@/main";
import { DateTime } from "luxon";

/**
 * Add a +234 country code to phone number
 *
 * @param {String} phoneNo
 */
export const appendNgCountryCode = (phoneNo) => {
  if (phoneNo.includes("+234")) return phoneNo;
  return phoneNo[0] === "0" ? `+234${phoneNo.slice(1)}` : `+234${phoneNo}`;
};

export const formatDate = (date, format = DateTime.DATE_FULL) => {
  if (!date) {
    return "";
  }

  const dateTime = DateTime.fromISO(date);

  return dateTime.toLocaleString(format);
};

export const formatPercent = (number) => {
  const formatter = new Intl.NumberFormat(`en-NG`, {
    style: "percent",
    minimumFractionDigits: 2,
  });
  return formatter.format(number);
};

/**
 * Formats money
 * @param {String} amount
 * @param {Boolean} addSign
 */
export const formatMoney = (
  number = 0,
  countryCode = "NG",
  currencyCode = "NGN"
) => {
  if (isNaN(parseFloat(countryCode))) {
    countryCode = "NG";
  }
  if (isNaN(parseFloat(currencyCode))) {
    currencyCode = "NGN";
  }
  const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
  });
  return formatter.format(number);
};
/**
 * Captilize first letter of a string
 *
 * @param {String} str
 */
export const capitalizeFirstLetter = (str) => {
  if (str === null) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toWords = (num = 0) => {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  let numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + " " + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " hundred";
    else
      return (
        ones[numString[0]] +
        " hundred and " +
        toWords(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " thousand";
    if (end < 100) return ones[numString[0]] + " thousand and " + toWords(end);
    return ones[numString[0]] + " thousand " + toWords(end);
  }
};

/**
 * Generate a toast
 *
 * @param {Object} toastData
 * @param {String} toastData.message
 * @param {Number} toastData.autoHideDelay
 * @param {Boolean} toastData.appendToast
 * @param {String} toastData.variant
 */
export const createToast = ({
  message,
  autoHideDelay = 3500,
  appendToast = false,
  variant = "success",
}) => {
  app.$bvToast.toast(message, {
    toaster: "b-toaster-top-center",
    autoHideDelay,
    appendToast,
    variant,
  });
};

import nigerianBanks from "./nigerianBanks";
export const bankLogo = (code) => {
  const name = nigerianBanks[code].toLowerCase().replace(/\s/g, "_");
  return require(`@/assets/icons/bank-logos/${name}.svg`);
};

export const extractAmount = (changesettings, investment) => {
  let amount = 0;
  if (changesettings.ispercentage) {
    amount =
      Number(investment.installment) * (Number(changesettings.amount) / 100);
  } else {
    amount = Number(changesettings.amount);
  }

  return amount;
};

export const smoothScroll = (duration = 1000) => {
  let cosParameter = window.scrollY / 2,
    scrollCount = 0,
    oldTimestamp = performance.now();

  function step(newTimestamp) {
    scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
    if (scrollCount >= Math.PI) window.scrollTo(0, 0);
    if (window.scrollY === 0) return;
    window.scrollTo(
      0,
      Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
    );
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
};
